<template>
  <div class="pt-4">
    <router-view> </router-view>
  </div>
</template>

<script>
export default {
  name: "UpdateBill",
  props: {
    dark: {
      type: Boolean,
    },
  },
}
</script>

<style></style>
