<template>
  <v-app>
    <Login />
  </v-app>
</template>

<script>
import Login from "@/components/Login"

export default {
  name: "User",
  components: {
    Login,
  },
}
</script>

<style></style>
