<template>
  <div id="app">
    <router-view></router-view>
    <!-- <home></home> -->
  </div>
</template>

<script>
import Home from "@/views/Home"
export default {
  components: {
    Home,
  },
}
</script>

<style></style>
